import React, { useEffect } from 'react';
import Navbar from "../components/Navbar";
import "../style.css";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { FaRunning, FaPaintBrush, FaMusic, FaBook, FaLeaf, FaCalculator } from 'react-icons/fa';
import Footer from "../components/Footer";
import Animations from "../components/Animations";
import AwardsSection from './AwardsSection';
import ParentsTalk from './ParentsTalk';
import DownloadAppSection from './DownloadAppSection';

// import SpeedDialComponent from "../components/SpeedDialComponent";

const Home = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Navbar />
      
      
      <div className="slider-container">
        <div className="slider">
          <div className="slide">
            <div className="slider-text-small block animatable bounceInLeft">Welcome to </div>
            <div className="slider-text-large block animatable bounceInRight">GD Goenka School, Hyderabad</div>
            <div className="slider-text-description block animatable bounceInLeft">
              A Leading Educational Institution Committed to Excellence
            </div>
            <Link
                to="/more"
              
              className="slider-button slider-button-learn-more block animatable bounceInRight block animatable bounceInLeft">
              Learn more <i className="icon-right-open"></i>
            </Link>
            <div className="three-btn">
              <Link
                to="/faculty"
                className="slider-button slider-button-our-teachers">
                Our Faculty <i className="icon-right-open"></i>
              </Link>
              <Link
                to="/infrastructure"
                className="slider-button slider-button-all-classes">
                All Facilities <i className="icon-right-open"></i>
              </Link>
              <Link
                to="/contact"
                className="slider-button slider-button-contact-us">
                Contact Us <i className="icon-right-open"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <Animations /> */}

      <div className="boxes-container">
        <div className="box box1 block animatable bounceInLeft">
          <img
            src="./images/home-about.jpg"
            alt="Box 1 Image"
            className="box-image"
          />
        </div>
        <div className="box box2 block animatable bounceInRight">
          <h2 className="box-title">About GD Goenka Group</h2>
          <p className="box-text">
            The GD Goenka Group, with its transcontinental presence, is a leader
            in education, known for its commitment to excellence and quality of
            life. Since the establishment of the first school in 1994, the group
            has expanded to numerous institutions from pre-schools to
            post-graduate institutes, setting benchmarks against global
            standards. With over 60 schools in India and abroad, GD Goenka is
            synonymous with quality education, fostering young minds and
            nurturing future leaders.
          </p>
          <Link to="/faculty" className="button block animatable moveUp">
          Our Faculty <i className="icon-right-open"></i>
          </Link>
        </div>
      </div>

      <div className="box-shiv block animatable moveUp">
        <h1 className="box-title">Shri Shiva Ganga Society</h1>
        <Typography>
          Founded in 2017 by Chairman Mr. Thota Shiva Yadav, Shri Shiva Ganga
          Educational Society aims to provide a supportive environment for
          students to gain practical knowledge. The society focuses on
          developing intercultural skills, global citizenship, and core life
          competencies, shaping students into ethical global leaders.
        </Typography>
      </div>

      

      <div className="section">
      <div className="section_wrapper">
        <div className="wrap one-fourth">
          <div className="mcb-wrap-inner">
            <div className="column one ls">
              <div className="column_attr block animatable moveUp">
                <h2>Holistic Development</h2>
                <h5>Our teachers develop children's skills.</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="wrap three-fourth">
          <div className="mcb-wrap-inner">
            <div className="column one-second">
              <div className="column_attr sport-lessons block animatable moveUp">
                <h4><FaRunning style={{ fontSize: 30, color: "#D6291E", marginRight: 10 }} /> Academic excellence</h4>
              </div>
            </div>
            <div className="column one-second">
              <div className="column_attr drawing-lessons block animatable moveUp">
                <h4><FaPaintBrush style={{ fontSize: 30, color: "#D6291E", marginRight: 10 }} /> Physical education </h4>
              </div>
            </div>
            <div className="column one-second">
              <div className="column_attr music-lessons block animatable moveUp">
                <h4><FaMusic style={{ fontSize: 30, color: "#D6291E", marginRight: 10 }} /> Emotional & social growth </h4>
              </div>
            </div>
            <div className="column one-second">
              <div className="column_attr history-lessons block animatable moveUp">
                <h4><FaBook style={{ fontSize: 30, color: "#D6291E", marginRight: 10 }} /> Creative & artics expression</h4>
              </div>
            </div>
            <div className="column one-second">
              <div className="column_attr nature-lessons block animatable moveUp">
                <h4><FaLeaf style={{ fontSize: 30, color: "#D6291E", marginRight: 10 }} /> Life skills</h4>
              </div>
            </div>
            <div className="column one-second">
              <div className="column_attr math-lessons block animatable moveUp">
                <h4><FaCalculator style={{ fontSize: 30, color: "#D6291E", marginRight: 10 }} /> Stem education</h4>
              </div>
            </div>
            <div className="column one">
              <div className="column_attr block animatable moveUp">
                <p>Holistic development in children focuses on nurturing the mind, body, and spirit to create well-rounded individuals. This approach integrates academic learning with physical, emotional, social, and creative growth. By emphasizing a balanced development, children learn to excel not only in academics but also in life skills, emotional intelligence, and interpersonal relationships. Holistic education aims to foster critical thinking, resilience, and a lifelong love for learning, preparing children to navigate the complexities of the modern world with confidence and competence.</p>
                <Link to='/classes' className="button" href="https://themes.muffingroup.com/be/school2/classes/">
                  <span className="button_icon"><i className="icon-right-open"></i></span>
                  <span className="button_label">Learn more</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AwardsSection />

    <ParentsTalk />

    <DownloadAppSection />

    {/* <SpeedDialComponent /> */}

    <Footer />
    </>
  );
};

export default Home;
