import React, { useEffect } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const MandatoryDisclosure = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Navbar />
      <div className="disclosure-container">
        <h1>MANDATORY PUBLIC DISCLOSURE</h1>

        <section>
          <h2>A: GENERAL INFORMATION</h2>
          <table className="disclosure-table">
            <tbody>
              <tr>
                <td><strong>U-DISE code slotted to the school:</strong></td>
                <td>36230600901</td>
              </tr>
              <tr>
                <td><strong>Name of the School:</strong></td>
                <td>G D GOENKA SCHOOL</td>
              </tr>
              <tr>
                <td><strong>Name of the Trust/Society/ Company under section-8) running the School:</strong></td>
                <td>SHRI SHIVA GANGA EDUCATIONAL SOCEITY</td>
              </tr>
              <tr>
                <td><strong>Trust Address 1:</strong></td>
                <td>D.No: 3-12-92/555, Survey no. 13/P,14/P & 15/P,</td>
              </tr>
              <tr>
                <td><strong>Trust Address 2:</strong></td>
                <td>Rock Town Colony, Thummabowli, L.B.Nagar</td>
              </tr>
              <tr>
                <td><strong>Trust Address 3:</strong></td>
                <td>Hyderabad, Ranga Reddy Dist.,TELANGANA</td>
              </tr>
              <tr>
                <td><strong>Is the Trust/Society/ Company duly registered?</strong></td>
                <td>YES</td>
              </tr>
              <tr>
                <td><strong>Who is the competent registering authority?</strong></td>
                <td>REGISTRAR OF SOCIETIES</td>
              </tr>
              <tr>
                <td><strong>Registration validity End date?</strong></td>
                <td>PERPETUAL</td>
              </tr>
              <tr>
                <td><strong>Trust/Society/Company Registration No.:</strong></td>
                <td>1612 OF 2014</td>
              </tr>
              <tr>
                <td><strong>Standard/level/class up to which the school is running:</strong></td>
                <td>10th</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section>
          <h2>B. DOCUMENTS AND INFORMATION</h2>
          <p><a href="#">Society Regn. Certificate</a></p>
          <p><a href="#">NOC issued by the State Govt.</a></p>
          <p><a href="#">Recognition Certificate under RTE Act,2009 And its renewal certificates</a></p>
          <p><a href="#">Land certificate</a></p>
          <p><a href="#">Building Safety Certificate</a></p>
          <p><a href="#">Fire Safety Certificate</a></p>
          <p><a href="#">Self Certification by School</a></p>
          <p><a href="#">Water, Health & Sanitation Certificate</a></p>
        </section>

        <section>
          <h2>C: RESULTS AND ACADEMICS</h2>
          <p><a href="#">Fee Structure of the School</a></p>
          <p><a href="#">Annual Academic Calendar</a></p>
          <p><a href="#">List of School Management Committee (SMC)</a></p>
          <p><a href="#">List of Parents and Teachers Association (PTA) Members</a></p>
          <p><a href="#">List of POCSO</a></p>
          <p><a href="#">Grievance Committee Students</a></p>
        </section>

        <section>
          <h2>D: STAFF (TEACHING)</h2>
          <table className="disclosure-table">
            <tbody>
              <tr>
                <td><strong>Total No of Teachers:</strong></td>
                <td>75</td>
              </tr>
              <tr>
                <td><strong>PGT:</strong></td>
                <td>1</td>
              </tr>
              <tr>
                <td><strong>TGT:</strong></td>
                <td>26</td>
              </tr>
              <tr>
                <td><strong>PRT:</strong></td>
                <td>24</td>
              </tr>
              <tr>
                <td><strong>NTT:</strong></td>
                <td>10</td>
              </tr>
              <tr>
                <td><strong>Teachers section Ratio:</strong></td>
                <td>0.000711806</td>
              </tr>
              <tr>
                <td><strong>Details of Special Educator:</strong></td>
                <td>Sheikh Sumaya</td>
              </tr>
              <tr>
                <td><strong>Details of Counselor and wellness teacher:</strong></td>
                <td>Ms Nirousha</td>
              </tr>
            </tbody>
          </table>
        </section>

        <section>
          <h2>E: SCHOOL INFRASTRUCTURE</h2>
          <table className="disclosure-table">
            <tbody>
              <tr>
                <td><strong>Total Campus area of the School (In Sq.Mtrs):</strong></td>
                <td>8093.44 Square Meters</td>
              </tr>
              <tr>
                <td><strong>No and size of the Class Rooms (In Sq.Mtrs):</strong></td>
                <td>40, (50.5 Square meters each)</td>
              </tr>
              <tr>
                <td><strong>No and size of laboratories including computer labs (In SQ Mtrs):</strong></td>
                <td>
                  <ul>
                    <li>1- Computer Lab 76.03SqMtrs</li>
                    <li>1- Composite Science Lab 73.89 Sq.Mtrs</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td><strong>No and size of the Library (In Sq.Mtrs):</strong></td>
                <td>1- 255.48 Sq. Mtrs</td>
              </tr>
              <tr>
                <td><strong>Internet Facility:</strong></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td><strong>No of Girls Toilets:</strong></td>
                <td>18</td>
              </tr>
              <tr>
                <td><strong>No of Boys Toilets:</strong></td>
                <td>18</td>
              </tr>
              <tr>
                <td><strong>No of CWSN Boys:</strong></td>
                <td>4</td>
              </tr>
              <tr>
                <td><strong>No of CWSN Girls:</strong></td>
                <td>4</td>
              </tr>
              <tr>
                <td><strong>Ramp/ Lift:</strong></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td><a href="https://youtu.be/d9pDdaEouEo?si=5ZJDg357RLj0rsPm">Link of YouTube Video of the school</a></td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default MandatoryDisclosure;
