import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Curriculum = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Navbar />
      
      <div className="page-container">
        <h1 className="box-title2">Curriculum</h1>
        <p>The school follows the CBSE curriculum in conformity with the requirement of the board and its advisory body, the NCERT and the teaching methodology is a diverse mix of the most recent innovations in education. The assessment plan of the school is in accordance with the guidelines issued by CBSE. Assessments are conducted to assess learning in both scholastic as well as co-scholastic areas.</p>
        <p>The Central Board of Secondary Education (CBSE) is a national level board of education in India for public and private schools, controlled and managed by the Government of India. Established in 1929, today there are more than 28,000 schools in India and 240 schools in 28 foreign countries affiliated to the CBSE. All schools affiliated to CBSE follow the NCERT curriculum especially from class 9 to 12. The curriculum provided by CBSE is based on National Curriculum Framework-2005 and seeks to provide opportunities for students to achieve excellence in learning. As the leading national Board, it has been instrumental in introducing various innovations in curriculum, examinations, capacity building of teachers, leadership development of principals, open learning, and education of the marginalized and differently abled.</p>
        <p>The CBSE also offers many skill education courses in collaboration with professional organizations, awards scholarship to students under various categories based on eligibility and merit, extends exemptions/concessions to Children with Special Needs (CWSN) students, provides counselling to board exam aspirants and holds sports competitions at Cluster/ Zonal and National level every year.</p>
        <p>GD Goenka School, Hyderabad employs a student-centered pedagogical approach, emphasizing active participation and holistic development. We believe in creating an engaging and interactive learning environment that stimulates curiosity, exploration, and independent thinking. The curriculum is aligned with National Education Policy – 2020 (NEP) with an aim to provide a balanced education that encompasses academic excellence, personal development, and practical skills.</p>
        <p>Our experienced and dedicated faculty members utilize innovative teaching methods, incorporating technology, project-based learning, and collaborative activities to enhance student learning outcomes. We prioritize individual attention and foster a nurturing atmosphere where every student can thrive and realize their full potential.</p>
        <p>At GDGS, the curriculum and pedagogy at the Foundational Level is multi-faceted, multi-level, play-based, activity-based, and inquiry-based learning with the focus on the domains of: physical and motor development, cognitive development, socio-emotional-ethical development, cultural/artistic development, and the development of communication and early language, literacy, and numeracy. The spotlight is on developing social capacities, sensitivity, good behaviour, courtesy, ethics, personal and public cleanliness, teamwork, and cooperation.</p>
        <p>Aspects of more formal and interactive classroom learning are adopted at the Preparatory Stage, in order to lay a solid groundwork across subjects, including reading, writing, speaking, physical education, art, languages, science, and mathematics.</p>
        <p>The curriculum at the Middle Stage emphasizes on learning and discussion of the more abstract concepts in each subject, experiential learning, critical thinking, and subject integration thereby laying foundation for a multidisciplinary study at the secondary stage.</p>
        <p>Experiential learning, including hands-on learning, arts-integrated and sports-integrated education, story-telling-based pedagogy are a part of the standard pedagogy within each subject at all levels with an aim to shift classroom transactions towards competency-based learning.</p>
      </div>
      
      <Footer />
    </>
  );
}

export default Curriculum;
