import React, { useState } from "react";
import {
    Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";


import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme)=>({
    link:{
        textDecoration:"none",
        color: "#000",
        fontSize: "20px",
    },
    icon:{
        color: "#000",
        width:'10px'
    },profileImg: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      objectFit: "cover",
      // marginLeft: theme.spacing(2),
      margin:'auto',
     
    },
}));

function DrawerComponent() {
    const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);


  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)} className="navbar"
      >
        <List>
        <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/" className={classes.link}>HOME</Link>
            </ListItemText>
          </ListItem>
          <Divider/>


          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/about" className={classes.link}>ABOUT US</Link>
            </ListItemText>
          </ListItem>
          <Divider/>


          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/contact" className={classes.link}>CONTACT US</Link>
            </ListItemText>
          </ListItem>
          <Divider/>
          

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/more" className={classes.link}>MORE</Link>
            </ListItemText>
          </ListItem>
          {/* <Divider/> */}
          
          
        </List>


      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
export default DrawerComponent;
