import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Classes = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (

    
    <>

    <Navbar />
    
    <div className="page-container">
      <h1>Classes</h1>
      <h2>1. Academic Excellence:</h2>
      <ul>
        <li>Rigorous curriculum designed to challenge and inspire students.</li>
        <li>Integration of modern teaching methods and technology.</li>
      </ul>

      <h2>2. Physical Development:</h2>
      <ul>
        <li>Extensive sports facilities and programs to promote physical fitness and teamwork.</li>
        <li>Regular sports events and competitions.</li>
      </ul>

      <h2>3. Emotional and Social Growth:</h2>
      <ul>
        <li>Focus on emotional intelligence and social skills through various activities.</li>
        <li>Counseling and support systems in place for students' well-being.</li>
      </ul>

      <h2>4. Creative and Artistic Expression:</h2>
      <ul>
        <li>Opportunities in music, dance, drama, and visual arts.</li>
        <li>Encouraging participation in cultural events and competitions.</li>
      </ul>

      <h2>5. Life Skills:</h2>
      <ul>
        <li>Programs to develop critical thinking, problem-solving, and leadership skills.</li>
        <li>Workshops and seminars on practical life skills and career guidance.</li>
      </ul>
    </div>

    <div className="page-container">
      <h1>Educational Concepts</h1>
      <h2>1. STEAM Education:</h2>
      <ul>
        <li>Integrated approach to learning Science, Technology, Engineering, Arts, and Mathematics.</li>
        <li>Hands-on projects and real-world applications.</li>
      </ul>

      <h2>2. Digital Literacy:</h2>
      <ul>
        <li>Incorporation of digital tools and platforms in the learning process.</li>
        <li>Training in coding, robotics, and other emerging technologies.</li>
      </ul>

      <h2>3. Inclusive Education:</h2>
      <ul>
        <li>Commitment to providing an inclusive environment for all students.</li>
        <li>Special programs and support for students with diverse learning needs.</li>
      </ul>

      <h2>4. Global Perspective:</h2>
      <ul>
        <li>Exchange programs and international collaborations.</li>
        <li>Curriculum that includes global issues and multicultural education.</li>
      </ul>

      <h2>5. Environmental Awareness:</h2>
      <ul>
        <li>Education on sustainability and environmental responsibility.</li>
        <li>Initiatives and projects aimed at conservation and eco-friendly practices.</li>
      </ul>
    </div>

    <Footer />
    
    </>
  )
}

export default Classes;