import React, { useEffect } from 'react';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const About = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>

    <Navbar />

    <div className="about-bb-section">
      <div className="about-bb-section_wrapper">
        <div className="about-bb-wrap about-bb-two-fifth">
          <div className="about-bb-wrap-inner">
            <div className="about-bb-column about-bb-column_column">
              <div className="about-bb-column_attr">
                <h1 className="about-bb-heading-main block animatable bounceInLeft">ABOUT</h1>
                <h1 className="about-bb-heading-sub block animatable bounceInLeft">Why GD Goenka School, Hyderabad?</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="about-bb-wrap about-bb-three-fifth">
          <div className="about-bb-wrap-inner">
            <div className="about-bb-column about-bb-column_image">
              <div className="about-bb-image_frame">
                <div className="about-bb-image_wrapper">
                  <img
                    className="about-bb-image block animatable bounceInRight"
                    src="./images/gallery_4.png"
                    alt="barman2-subheader"
                    width="780"
                    height="581"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="why-goenka-container">
      <section className="section-1 block animatable bounceInLeft">
        <h2>Why GD Goenka School, Hyderabad?</h2>
        <p>
          At G. D Goenka School, Hyderabad, one of the upcoming best CBSE schools, we endeavor to promote a classroom of thinkers who would have the courage and conviction to lead the world with their astute insight, competency, caliber, and impeccable values. The school’s motto ‘Together - Towards - Tomorrow Seamlessly’ epitomizes the school’s commitment to broaden the students’ horizons and enable them to carve an integrated and an enlightened world - a world that fosters unity yet celebrates the uniqueness of each individual.
        </p>
      </section>

      <section className="section-2 block animatable bounceInRight">
        <h3>VISION</h3>
        <p>
          To give the best to each TOT every day. To find the best in each TOT some way. A LEGACY OF EXCELLENCE. Today, under the aegis of G D Goenka Group, the G D Goenka Schools are regarded as centres of educational excellence globally and as best CBSE schools all over India. The school philosophy is based on a set of strongly held beliefs which is put into action every day by every member of the institution. It’s a philosophy of treating each child in a sensitive manner and providing appropriate development programs so as to encourage not just learning but also the love of learning. It is also based on providing an environment that is safe, clean and healthy and child-oriented. An environment where classrooms are allowed to explore, experience and most importantly succeed.
        </p>
      </section>

      <section className="section-3 block animatable bounceInLeft">
        <h3>MISSION</h3>
        <p>
          We will create an environment in which all children and adults feel welcomed, respected, trusted and an important part of the school. We will create an environment where each child will be valued for their individually and diverse capabilities as they will be taught to face the future and contribute to society with compassion courage Knowledge and Vision. We will create a child-centric, brain-compatible environment to provide holistic development which will create visionaries in any ever-changing world.
        </p>
      </section>
    </div>

    <Footer />
    
    </>
  )
}

export default About