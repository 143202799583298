
import React from 'react';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const FAQs = () => {
  return (
    <>

    <Navbar />
    
    <div className="page-container">
      <h1>FAQs</h1>

      <h2>1. What is the admission process at GD Goenka, Hyderabad?</h2>
      <p>The admission process includes submitting an application form, an entrance test, and an interview. Detailed information can be found on our Admissions page.</p>

      <h2>2. What age groups does GD Goenka, Hyderabad cater to?</h2>
      <p>We offer programs for students from pre-primary to senior secondary levels.</p>

      <h2>3. What curriculum does GD Goenka, Hyderabad follow?</h2>
      <p>We follow the CBSE curriculum, which emphasizes both academic excellence and holistic development.</p>

      <h2>4. Are there any extracurricular activities available?</h2>
      <p>Yes, we offer a wide range of extracurricular activities including sports, arts, music, and clubs.</p>

      <h2>5. How does GD Goenka ensure student safety?</h2>
      <p>We have strict safety protocols, CCTV surveillance, and trained security personnel on campus.</p>

      <h2>6. What facilities are available at GD Goenka, Hyderabad?</h2>
      <p>Our facilities include modern classrooms, advanced science labs, a computer lab, sports fields, and a medical room.</p>

      <h2>7. How are parents kept informed about their child's progress?</h2>
      <p>We have regular parent-teacher meetings, progress reports, and an online portal for real-time updates.</p>

      <h2>8. What is the student-teacher ratio?</h2>
      <p>We maintain a low student-teacher ratio to ensure personalized attention and effective learning.</p>

      <h2>9. Are there any transportation services provided?</h2>
      <p>Yes, we offer safe and reliable transportation services for students across various routes.</p>

      <h2>10. What kind of support is available for students with special needs?</h2>
      <p>We have inclusive education programs and specialized support staff to cater to students with diverse learning needs.</p>

      <h2>11. How does GD Goenka integrate technology into the curriculum?</h2>
      <p>We use digital classrooms, e-learning tools, and provide training in coding and robotics to enhance digital literacy.</p>

      <h2>12. What sports facilities are available?</h2>
      <p>Our sports facilities include fields for cricket, football, basketball courts, and athletics tracks.</p>

      <h2>13. What healthcare services are provided on campus?</h2>
      <p>We have a medical room staffed by qualified professionals and conduct regular health check-ups in collaboration with Kaminene Hospital.</p>

      <h2>14. How can I stay updated with school events and news?</h2>
      <p>Our News Blog provides updates on school events, achievements, and other important information.</p>

      <h2>15. What measures are taken to ensure academic excellence?</h2>
      <p>We have experienced faculty, a rigorous curriculum, regular assessments, and personalized support to ensure academic success.</p>
    </div>
    
    <Footer />
    
    </>
  );
};

export default FAQs;
