import React, { useState, useEffect } from "react";


const ParentsTalk = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const testimonials = [
    {
      text: "“Best school within city limits with all the necessary facilities for the overall development of a child.”",
      client: "Tapan Dasari Grade 1",
      img: "./images/testimonials-img1-100x100.jpg",
    },
    {
      text: "The opportunity of having both our kids with GD Goenka School, Hyderabad is special for us. The teacher gets to know your children as individuals and pushes them to levels they can and should achieve.",
      client: "Drishti Pilania - KG 2 & Veer Singh Pilania - Gr 4",
      img: "./images/testimonials-img2-100x100.jpg",
    },
    {
      text: "I strongly recommend the school to those looking for their children’s overall development and growth. Good faculty and a friendly atmosphere for children.",
      client: "Apeksha Garg",
      img: "./images/testimonials-img3-100x100.jpg",
    },
  ];

  // Function to get a random index for the testimonials
  const getRandomIndex = () => {
    let newIndex = Math.floor(Math.random() * testimonials.length);
    return newIndex === activeIndex ? (newIndex + 1) % testimonials.length : newIndex;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(getRandomIndex());
    }, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [activeIndex]);

  return (
    <section className="parentsTalk-section">
      <div className="parentsTalk-container">
        <div className="parentsTalk-heading">
          <h2 className="block animatable bounceInLeft">PARENT'S TALK</h2>
        </div>

        <div className="parentsTalk-testimonialsSection">
          <div className="parentsTalk-sliderPagination block animatable bounceInLeft">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`pagination-bullet ${index === activeIndex ? 'active' : ''}`}
                role="button"
                aria-label={`Go to slide ${index + 1}`}
                onClick={() => setActiveIndex(index)}
              ></span>
            ))}
          </div>

          <div className="parentsTalk-testimonials">
            <div className="testimonial-item active block animatable bounceInLeft">
              <p>{testimonials[activeIndex].text}</p>
              <div className="testimonial-client">
                <img src={testimonials[activeIndex].img} alt={testimonials[activeIndex].client} />
                <p>{testimonials[activeIndex].client}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParentsTalk;
