import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const AdministrationRules = () => {
  return (
    <>

    <Navbar />

    <div className="banner">
        <img src="./images/Administration-01.jpg" />
      </div>
    
    <div className="page-container">
      <h1>Administration Rules</h1>
      <p>To ensure a safe and effective learning environment, GD Goenka, Hyderabad has established a set of administration rules. These rules include punctuality, adherence to the school dress code, respect for teachers and peers, and active participation in school activities. Maintaining discipline and order is essential for the smooth functioning of the school and for fostering a positive atmosphere conducive to learning.</p>
      <p>Parents are encouraged to support and reinforce these rules at home to ensure consistency and to help their children understand the importance of discipline and respect. Any form of misconduct or violation of school policies is addressed promptly to uphold our standards. By working together, we can create an environment where every student can thrive and achieve their full potential.</p>
    </div>


<Footer />

</>
  )
}

export default AdministrationRules;