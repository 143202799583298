import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Faculty = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const facultyList = [
    "MS.ANITHA UNNAVA",
    "MS.RAGAMAYEE MALYALA",
    "MS.MAMATHA RAMASAHAYAM",
    "MS.BHARANI DARAM",
    "BHANU DEEPTHI NANDULA",
    "MS.SUMAN VARMA",
    "MS.MANJU BHARGAVI CHINTHAPALLY",
    "MS.SULTANA ASHFAQ",
    "MS.TRICHA REDDY GARLAPATI",
    "MS.ABEER MOHAMMED",
    "MS.SIREESHA VIPPARTHI",
    "MS.SREE PRIYA KEERTHI",
    "MR.MANGAL SINGH",
    "MR. SRINIVAS KUSUMANI",
    "MR.NAGESH GUMMADAVELLI",
    "MS.PADMAJA TUPURI",
    "MS.SWATHI GANTLA",
    "MS.RIZWANA BEGUM",
    "MS.SWETHA ORUGANTI",
    "MS.SHALINI REDDY BUJUNOORI",
    "MS.SIRISHA TATA",
    "MS. HANISHA ABBADI",
    "YASHASWINI MUDIRAJ",
    "MS.VIJAYA LAKSHMI KRISHNA MURTHY",
    "MS.DIVYA GURRAM",
    "MS.AISHWARYA BONDALI",
    "MS. SUVARNA MALOJI",
    "MS.VASANTHA TANNERU",
    "MS.SWETHA ORUGANTI",
    "MS.SHALINI REDDY BUJUNOORI",
    "MS.PRATHYUSHA MAYA",
    "MS.PRASANNA LAKSHMI GOSHIKA",
    "MS.PALLAVI KUMARI",
    "MS.SWAPNA MOLUGU",
    "MS.BINDU K",
    "MS.KHATIJA BEGUM",
    "MS.ANITA CAMPBELL",
    "MS.USHA JYOTHI TUMMALA",
    "MS. SAI VINITHA TUNIKI",
    "MR.ARINDAM BANERJEE",
    "MR.VENKATESH BADDULA",
    "MR.VENKATA RAMANA JADDETI",
    "MS.SRAVANI CETAKOR",
    "MR.NAGRAJ DARNAL",
    "MS.HARINI S.T.P",
    "MS.BHAGYA LAXMI ALUGOJU",
    "MS.KRISHNA PRIYA MADALA",
    "MS.SIRISHA DHARMALA",
    "MS.SHRUTI KOMIRISHETTY",
    "MS.UJWALA PANGULURI",
    "MS.DEEPIKA KANAKAGIRI",
    "MS. SRAVANTHI GADEELA",
    "MS.VIJAYA KUMARI REMALLI",
    "MS. USHASREE I",
    "MS. MADHURIKA",
    "MS.P.SRI LAXMI",
    "MS. JULURU HARIKA",
    "MS. M SAI SNEHA",
    "MR. MADAGONI UPENDAR KUMAR",
    "MS.K PRICELLA BABU",
    "MR.Y SRINIVAS",
    "MS. SEEMA VERMA",
    "MS. AYESHA ANJUM",
    "MS. K CHANDANA SREE",
    "MS. M KALYANI",
    "MS. S SANTHOSHI",
    "MS. S PAVANI RAO",
    "MS. RANGA RAJU VENNELA",
    "MS. ALLAGADDA MANASA",
    "MS. P SREE BRAMHA LAKSHMI",
    "MS. K RADHA",
    "MS. NAIKOTI SOWMYA",
    "MS. S SAHASRA",
    "MR.H.J SAGAR CHAKRAVARTHY",
    "MS. SONALI JAISWAL",
    "MR. SANTHOSH KUMAR",
    "MS. SITAMAHALAKSHMI",
    "MS. B.NIHARIKA",
    "MS. VYSHNAVI",
  ];

  return (
    <>
    
    <Navbar />
    
    <div className="faculty-container">
      <h1 className="box-title2">FACULTY</h1>
      <p className="faculty-description">
        Cultivating a culture of continual learning, a team of highly-qualified faculty members with excellent academic credentials direct their efforts to guide and deliver progressive and inclusive learning to the students. At GDGS, the faculty takes ample care to include tangible experiences and examples within the scope of discussions to make learning more comprehensive for the students. It is not only the classrooms where the contribution of our teachers remains confined to but extends to the holistic development of the students as well. Also, at GDGS, we give priority to faculty training and development which ensures consistent quality in the classroom and beyond.
      </p>
      <h2 className="faculty-subtitle">SCHOOL FACULTY</h2>
      <table className="faculty-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {facultyList.map((name, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <Footer />
    
    </>
  );
};

export default Faculty;
