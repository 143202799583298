import React, { useEffect } from 'react';

const DownloadAppSection = () => {

    useEffect(() => {
        const handleScroll = () => {
          const windowHeight = window.innerHeight;
          const scrollY = window.scrollY;
    
          const animatables = document.querySelectorAll('.animatable');
    
          animatables.forEach(animatable => {
            const elementTop = animatable.getBoundingClientRect().top + scrollY;
            const elementBottom = elementTop + animatable.clientHeight;
    
            // Check if element is in the viewport
            if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
              animatable.classList.add('animated');
              animatable.classList.remove('animatable');
            } else {
              // Reset the animation when the element is out of view
              animatable.classList.remove('animated');
              animatable.classList.add('animatable');
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger animation on mount
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


  return (
    <section className="gdapp-section gdapp-inner-section gdapp-element gdapp-element-288d91f gdapp-section-boxed gdapp-section-height-default">
      <div className="gdapp-background-overlay"></div>
      <div className="gdapp-container gdapp-column-gap-custom">
        <div className="gdapp-column gdapp-col-50 gdapp-inner-column gdapp-element gdapp-element-0475345">
          <div className="gdapp-widget-wrap gdapp-element-populated">
            <div className="gdapp-element gdapp-element-d794b3f gdapp-widget__width-inherit gdapp-download_app_title gdapp-widget gdapp-thim-ekits-heading">
              <div className="gdapp-widget-container block animatable bounceInLeft">
                <div className="gdapp-sc_heading">
                  <h2 className="gdapp-title">Download <span className="gdapp-thim-color">Our App!</span></h2>
                  <div className="gdapp-sub-heading">
                    <p>
                      GD Goenka is one stop solution for all of your digital needs, we don’t just provide solutions – we provide companies with a competitive advantage. GD Goenka APP is focused on making day to day workload simple and delightful.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdapp-element gdapp-element-95fcd9a gdapp-widget gdapp-widget-html">
              <div className="gdapp-widget-container block animatable bounceInLeft">
                <div className="gdapp-download_apps_icons">
                  <a href="https://play.google.com/store/apps/details?id=com.gdgoenkaschool" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }}>
                    <img decoding="async" src="./images/googleplaybutton.png" alt="Download on Google Play" style={{ width: '180px' }} />
                  </a>
                  <a href="https://apps.apple.com/us/app/gd-goenka-parent-hyderabad/id6447497756" target="_blank" rel="noopener noreferrer" style={{ marginRight: '15px' }}>
                    <img decoding="async" src="./images/appstorebutton.png" alt="Download on App Store" style={{ width: '180px' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gdapp-column gdapp-col-50 gdapp-inner-column gdapp-element gdapp-element-3925bac gdapp-app_img">
          <div className="gdapp-widget-wrap gdapp-element-populated">
            <div className="gdapp-element gdapp-element-6cb715b gdapp-widget gdapp-widget-image">
              <div className="gdapp-widget-container block animatable moveUp">
                <img
                  loading="lazy"
                  decoding="async"
                //   width="514"
                //   height="530"
                  src="./images/phonemock.png"
                  alt="App Display"
                  className="gdapp-attachment-full gdapp-size-full gdapp-wp-image-13670"
                //   srcSet="./images/phonemock.png"
                //   sizes="(max-width: 514px) 100vw, 514px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadAppSection;
