import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const SportsManagement = () => {
  return (
    <>

    <Navbar />

    <div className="banner">
        <img src="./images/Sports-01.jpg" />
      </div>
    
    <div className="page-container">
      <h1>Sports Management</h1>
      <p>At GD Goenka, Hyderabad, we understand the vital role sports play in the holistic development of students. Our comprehensive sports management program includes a variety of activities such as cricket, football, basketball, and athletics. Our state-of-the-art facilities and experienced coaches provide students with the best training opportunities, encouraging both individual excellence and teamwork. Regular sports events and inter-school competitions help students develop a competitive spirit and sportsmanship.</p>
      <p>We believe in the physical and mental benefits of active participation in sports. Our program is designed to foster discipline, resilience, and leadership skills. Through sports, students learn valuable life lessons, such as perseverance, strategic thinking, and collaboration. By promoting a healthy and active lifestyle, we aim to enhance the overall well-being of our students, preparing them to face challenges with confidence.</p>
    </div>

    <Footer />
    
    </>



  )
}

export default SportsManagement