import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const NewsBlog = () => {
  return (
    <>
    
    <Navbar />

    <div className="banner">
        <img src="./images/News-01.jpg" />
      </div>
    
    <div className="page-container">
      <h1 className='box-title2'>News Blog</h1>
      <p>Stay connected with the vibrant life at GD Goenka, Hyderabad through our News Blog. Here, we share updates on school events, academic achievements, sports victories, and cultural celebrations. Our blog is a window into the dynamic activities that make our school community unique and engaging. It’s a platform for celebrating our students’ successes and keeping parents and the community informed about what’s happening at our school.</p>
      <p>From interviews with faculty and student spotlights to coverage of special events and educational articles, our News Blog offers a wealth of information and insights. We aim to foster a sense of community and pride, highlighting the hard work and dedication of our students and staff. Join us in celebrating the achievements and milestones of GD Goenka, Hyderabad.</p>
    </div>
    
    <Footer />
    
    </>
  )
}

export default NewsBlog