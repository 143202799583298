import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:3000/submit-contact",
        formData
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="contact-cc-container">
        <div className="contact-cc-box contact-cc-box1">
          <h2>SCHOOL CONNECT</h2>
          <div className="contact-cc-inboxs">
            <div>
              <p>
                <h3>
                  School Relationship In-charge (for General information)
                </h3>
              </p>
              <p>Mobile No. - 7995001771</p>
              <p>
                <strong>School Fee Desk (for Fee and dues)</strong>
              </p>
              <p>Mobile No. - 9121283337</p>
            </div>
            <div>
              <p>
                <h3>School Transport Manager (School transport)</h3>
              </p>
              <p>Mobile No. - 9281019198</p>
              <p>
                <strong>School Official Mail ID</strong>
              </p>
              <p>info@gdgoenkahyderabad.com</p>
            </div>
          </div>
          <div className="contact-cc-inboxs">
            <div>
              <p>
                <h3>School HR mail ID</h3>
              </p>
              <p>hr@gdgoenkahyderabad.com</p>
              <p>
                <strong>School Principal’s Mail ID</strong>
              </p>
              <p>principal@gdgoenkahyderabad.com</p>
            </div>
            <div>
              <h3>SCHOOL OFFICE TIMINGS</h3>

              <p>9.00am to 4.00pm</p>

              <p>
                <strong>School Counselor</strong>
              </p>
              <p>11.00am to 12.30pm</p>
            </div>
          </div>
        </div>

        <div className="contact-cc-box contact-cc-box2">
          <form className="form-mainb">
            <div className="contact-cc-form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>

            <div className="contact-cc-form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>

            <div className="contact-cc-form-group">
              <label htmlFor="contact">Contact Number</label>
              <input type="tel" id="contact" name="contact" required />
            </div>

            <div className="contact-cc-form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                rows="5"
                required></textarea>
            </div>

            {/* <button type="submit" className="contact-cc-submit-button">
              Submit
            </button> */}
            <Link style={{textAlign:'center'}} to="/faculty" className="button block animatable moveUp">
            Submit <i className="icon-right-open"></i>
          </Link>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
