import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const TermsAndConditions = () => {
  return (
    <>

    <Navbar />
    
    <div className="page-container">
      <h1>Terms and Conditions</h1>
      <p>By accessing and using the GD Goenka, Hyderabad website, you agree to abide by our Terms and Conditions. These terms outline the rules and regulations for using our online platform, including the proper use of information, adherence to school policies, and compliance with applicable laws. Our goal is to provide a safe and respectful online environment for all users, promoting positive interactions and academic integrity.</p>
      <p>We reserve the right to modify these Terms and Conditions at any time, with updates posted on our website. Users are expected to stay informed of any changes and continue to follow the guidelines set forth. Misuse of the website, such as engaging in inappropriate behavior or violating school policies, may result in restricted access. We encourage users to act responsibly and maintain the integrity of our online community.</p>
    </div>
    
    <Footer />
    
    </>


  )
}

export default TermsAndConditions