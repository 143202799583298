import React, { useEffect } from 'react';
import './EnquiryForm.css'; // Import the CSS file
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';


const EnquiryForm = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    
  return (
   <>
   
   <Navbar />

    <div className="EnquiryForm-container site-content">
      <main id="main" className="site-main">
        <article id="post-12135" className="post-12135 page type-page status-publish hentry">
          <div className="entry-content">
            <div data-elementor-type="wp-post" data-elementor-id="12135" className="elementor elementor-12135">
              <section className="elementor-section elementor-top-section elementor-element elementor-element-71463fb elementor-section-boxed elementor-section-height-default elementor-section-height-default">
                <div className="elementor-container elementor-column-gap-no">
                  <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-88b5840">
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div className="elementor-element elementor-element-74968e3 thim-ekits-heading-text-left elementor-widget thim-ekits-heading elementor-widget-thim-heading">
                        <div className="elementor-widget-container">
                          <div className="sc_heading text-left">
                            {/* <h3 className="title">ENQUIRY FORM</h3> */}
                            <h1 className="box-title2">ENQUIRY FORM</h1>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-element elementor-element-db9c2bc enquiry_form elementor-widget elementor-widget-thim-ekits-contact-form-7">
                        <div className="elementor-widget-container">
                          <div className="thim-ekit-wpcf7">
                            <form className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate">
                              
                              <div className="row d-flex align-item-center" id='two-side'>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Select School</label>
                                  <select className="wpcf7-form-control wpcf7-select wide" id="school" name="selectSchool">
                                    <option value="Select">Select</option>
                                    <option value="GD Goenka La Petite, Saroornagar (Pre Nur - KG2)">GD Goenka La Petite, Saroornagar (Pre Nur - KG2)</option>
                                    <option value="GD Goenka School, LB Nagar, Hyderabad (Nur- Grade VIII)">GD Goenka School, LB Nagar, Hyderabad (Nur- Grade VIII)</option>
                                  </select>
                                </div>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Child Name</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="childName" />
                                </div>
                              </div>
                              <div className="row d-flex align-item-center" id='two-side' >
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Date Of Birth</label>
                                  <input className="wpcf7-form-control wpcf7-date" type="date" name="dob_7" />
                                </div>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Select Grade</label>
                                  <select className="wpcf7-form-control wpcf7-select wide" id="grade" name="selectGrade">
                                    <option value="Select">Select</option>
                                    <option value="Nursery">Nursery</option>
                                    <option value="KG-1">KG-1</option>
                                    <option value="KG-2">KG-2</option>
                                    <option value="Grade-1">Grade-1</option>
                                    <option value="Grade-2">Grade-2</option>
                                    <option value="Grade-3">Grade-3</option>
                                    <option value="Grade-4">Grade-4</option>
                                    <option value="Grade-5">Grade-5</option>
                                    <option value="Grade-6">Grade-6</option>
                                    <option value="Grade-7">Grade-7</option>
                                    <option value="Grade-8">Grade-8</option>
                                  </select>
                                </div>
                              </div>

                              <div className="row d-flex align-item-center" id='two-side'>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Previous School Name</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="schoolName" />
                                </div>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Email ID</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="emailId" />
                                </div>
                              </div>
                              <div className="row d-flex align-item-center" id='two-side'>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Annual Income</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="annualIncome" />
                                </div>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Address</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="address" />
                                </div>
                              </div>

                              <div className="row d-flex align-item-center" id='two-side'>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Primary Contact Number</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="primaryContact" />
                                </div>
                                <div className="col-sm-6 col-12 mb-20">
                                  <label>Secondary Contact Number</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="secondaryContact" />
                                </div>
                              </div>


                              <div className="row d-flex align-item-center" id='three-side'>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Father Name</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="fatherName" />
                                </div>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Father Qualification</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="fatherQualification" />
                                </div>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Father Profession</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="fatherProfession" />
                                </div>
                              </div>

                              <div className="row d-flex align-item-center" id='three-side'>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Mother Name</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="motherName" />
                                </div>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Mother Qualification</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="motherQualification" />
                                </div>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Mother Profession</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="motherProfession" />
                                </div>
                              </div>
                              <div className="row d-flex align-item-center" id='three-side'>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Aadhar Number</label>
                                  <input className="wpcf7-form-control wpcf7-text" type="text" name="aadhar" />
                                </div>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Would you like to opt for transport?</label>
                                  <div className="wpcf7-form-control wpcf7-radio">
                                    <label><input type="radio" name="transport" value="Yes" /> Yes</label>
                                    <label><input type="radio" name="transport" value="No" /> No</label>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-12 mb-20">
                                  <label>Cafeteria food</label>
                                  <div className="wpcf7-form-control wpcf7-radio">
                                    <label><input type="radio" name="FOOD" value="Yes" /> Yes</label>
                                    <label><input type="radio" name="FOOD" value="No" /> No</label>
                                  </div>
                                </div>
                              </div>

                              <div className="row d-flex align-item-center">
                                <div className="col-12 btn-sb">
                                  <button type="submit" className="button">Submit</button>
                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-905cbb2">
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div className="elementor-element elementor-element-7eaf87e elementor-widget elementor-widget-spacer">
                        <div className="elementor-widget-container">
                          <div className="elementor-spacer">
                            <div className="elementor-spacer-inner"></div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-element elementor-element-09b2c8a elementor-widget elementor-widget-heading">
                        <div className="elementor-widget-container">
                          <h5 className="elementor-heading-title elementor-size-default">Contact Us</h5>
                        </div>
                      </div>
                      <div className="elementor-element elementor-element-73ff278 elementor-widget elementor-widget-text-editor">
                        <div className="elementor-widget-container">
                          <div className="elementor-text-editor elementor-clearfix">
                            <p>
                              <strong>Address:</strong> 
                              GD Goenka, Hyderabad<br/>
                              <strong>Phone:</strong> 
                              1800 123 1234<br/>
                              <strong>Email:</strong> 
                              info@gdgoenkahyderabad.com
                            </p>
                          </div>
                        </div>
                      </div>
                      
                     
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </main>
    </div>
 
   
   <Footer />
   
   </>
  );
};

export default EnquiryForm;
