import React from 'react'
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

const AdvancedScienceLabs = () => {
  return (
    <>
    <Navbar />

    <div className="banner">
        <img src="./images/Science-001.jpg" />
      </div>
    
    <div className="page-container">
      <h1>Advanced Science Labs</h1>
      <p>GD Goenka, Hyderabad is proud to offer advanced science labs equipped with the latest technology and tools. Our Physics, Chemistry, and Biology labs provide students with hands-on learning experiences that complement their theoretical knowledge. These labs are designed to inspire curiosity and foster a deep understanding of scientific principles through experimentation and research.</p>
      <p>Safety is a top priority in our science labs. We have implemented strict safety protocols to ensure a secure environment for all students during their experiments. Our labs encourage students to explore, innovate, and develop critical thinking skills, preparing them for future scientific endeavors and careers in STEM fields. The practical exposure students gain in our labs is invaluable in nurturing a scientific mindset.</p>
    </div>
    
    <Footer />
    
    </>
  )
}

export default AdvancedScienceLabs;