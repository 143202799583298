import React from "react";
import "./Footer.css";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaYoutubeSquare,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="Footer" className="footer">
        <div className="widgets-wrapper">
          <div className="Footer-container">
            <div className="Footer-column one-fourth-footer">
              <aside className="widget_text widget custom-html">
                <h4 className="block animatable moveUp">SHORT LINKS</h4>
                <div className="textwidget custom-html-widget">
                  <ul>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/sports-management">Sports Management</Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/administration-rules">
                        {" "}
                        Administration Rules
                      </Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/advanced-science-labs">
                        Advanced Science Labs
                      </Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/computer-lab">Computer Lab</Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/news-blog">News Blog</Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/healthcare-blog">Healthcare Blog</Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="Footer-column one-fourth-footer">
              <aside className="widget_text widget custom-html">
                <h4 className="block animatable moveUp">HELP LINK</h4>
                <div className="textwidget custom-html-widget">
                  <ul>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/terms-and-conditions">
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/refund-policy">Refund Policy</Link>
                    </li>

                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/faqs">Faq's</Link>
                    </li>
                    <li>
                      {/* <span className="arrow">→</span>{" "} */}
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="Footer-column one-fourth-footer">
              <aside className="widget_text widget custom-html">
                <h4 className="block animatable moveUp">FOLLOW US</h4>
                <div className="textwidget custom-html-widget">
                  <ul style={{ display: "flex" }}>
                    <div>
                      <a
                        href="https://www.linkedin.com/company/gd-goenka-school-hyd/"
                        target="_blank"
                        rel="noopener noreferrer">
                        <FaLinkedin
                          style={{
                            fontSize: 30,
                            color: "#FFF",
                            margin: "0 10px",
                          }}
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.facebook.com/shiva.ganga.9237"
                        target="_blank"
                        rel="noopener noreferrer">
                        <FaFacebookSquare
                          style={{
                            fontSize: 30,
                            color: "#FFF",
                            margin: "0 10px",
                          }}
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.youtube.com/watch?v=BNQLR78QHfE"
                        target="_blank"
                        rel="noopener noreferrer">
                        <FaYoutubeSquare
                          style={{
                            fontSize: 30,
                            color: "#FFF",
                            margin: "0 10px",
                          }}
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://api.whatsapp.com/send?phone=7995001770"
                        target="_blank"
                        rel="noopener noreferrer">
                        <FaWhatsappSquare
                          style={{
                            fontSize: 30,
                            color: "#FFF",
                            margin: "0 10px",
                          }}
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://twitter.com/goenka_school"
                        target="_blank"
                        rel="noopener noreferrer">
                        <FaTwitterSquare
                          style={{
                            fontSize: 30,
                            color: "#FFF",
                            margin: "0 10px",
                          }}
                        />
                      </a>
                    </div>
                  </ul>
                </div>
              </aside>
            </div>
            <div className="Footer-column one-fourth-footer">
              <aside className="widget_text widget custom-html">
                <h4 className="block animatable moveUp">GD Goenka</h4>
                <div className="textwidget custom-html-widget">
                  <p>
                    D.no. 3-12-92/555 , Rock Town
                    <br /> Colony, L.B Nagar, Hyderabad 500068.
                  </p>
                  <p>
                    <a
                      className="contact-email"
                      href="mailto:info@gdgoenkahyderabad.com">
                      info@gdgoenkahyderabad.com
                    </a>
                    <br /> 7995001770, 7995001771, 9281042704
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <div className="footer_copy">
          <div className="Footer-container">
            <div className="Footer-column one">
              <a id="back_to_top" className="footer_button" href="#">
                <i className="icon-up-open-big"></i>
              </a>
              <div className="copyright">
              © 2024 GD Goenka School. All Rights Reserved.  
Designed and developed by <a style={{textDecoration:'none', color:'yellow'}} href="https://learnorama.in/" target="_blank">Learnorama Technologies Pvt. Ltd.</a>


              </div>
              <ul className="social"></ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
