import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

const ComputerLab = () => {
  return (
    <>
    
    <Navbar />

    <div className="banner">
        <img src="./images/Computer-01.jpg" />
      </div>
    
    <div className="page-container">
      <h1>Computer Lab</h1>
      <p>The computer lab at GD Goenka, Hyderabad is a center of digital innovation and learning. Equipped with modern computers, high-speed internet, and the latest software, our lab supports a wide range of activities from basic computing skills to advanced coding and robotics. Our curriculum is designed to integrate technology seamlessly, preparing students for the digital age.</p>
      <p>Students are provided with opportunities to learn and develop essential tech skills that are crucial in today’s world. Our computer lab fosters creativity, problem-solving, and innovation, encouraging students to think critically and explore new technologies. The hands-on experience they gain here is instrumental in preparing them for future academic and professional success in an increasingly digital world.</p>
    </div>
    
    <Footer />
    
    </>
  )
}

export default ComputerLab