import React from 'react';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';

// Define the actions for the SpeedDial
const actions = [
  { icon: <WhatsAppIcon />, name: 'WhatsApp', path: 'https://api.whatsapp.com/send?phone=7995001770' },
  { icon: <FacebookIcon />, name: 'Facebook', path: 'https://www.facebook.com/shiva.ganga.9237' },
  { icon: <YouTubeIcon />, name: 'YouTube', path: 'https://www.youtube.com/watch?v=BNQLR78QHfE' },
  { icon: <ArticleIcon />, name: 'News', path: '/news-blog' },
];



const SpeedDialComponent = () => {

    const navigate = useNavigate();

  const handleClick = (path) => {
    if (path.startsWith('http')) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

      
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        '& .MuiSpeedDial-fab': {
          backgroundColor: '#D6291E',
          
          '&:hover': {
            backgroundColor: '#D6291E',
             
          },
        },
      }}
      icon={<SpeedDialIcon sx={{ color: '#fff',background:'#D6291E' }} />}
      direction="up" 
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => handleClick(action.path)}
        />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialComponent;
