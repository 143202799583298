import React, { useState, useEffect } from "react";

const Awards = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <section className="awards-section">
      <div className="awards-container">
        <div className="awards-heading">
          <h2 className="awards-title block animatable bounceInRight">Awards</h2>
        </div>
        <div className="awards-slider">
          <div className="award-item">
            <div className="award-thumbnail block animatable bounceInLeft">
              <img
                src="./images/award-1.jpg"
                alt="Optimal Media Solutions (2016-2019)"
                className="award-image"
              />
            </div>
            <div className="award-content block animatable bounceInRight">
              <div className="award-name">Optimal Media Solutions (2016-2019)</div>
              <div className="award-description">
                Certificate of recognition as Top Emerging Pre-school in East Zone (Sroornagar), Times Excellence award by optimal media solutions.
              </div>
            </div>
          </div>
          <div className="award-item">
            <div className="award-thumbnail block animatable bounceInLeft">
              <img
                src="./images/award-2.jpg"
                alt="Assocham India-National Educational Excellence Awards 2018"
                className="award-image"
              />
            </div>
            <div className="award-content block animatable bounceInRight">
              <div className="award-name">Assocham India-National Educational Excellence Awards 2018</div>
              <div className="award-description">
                Best Preschool chain in India by Honorable State Minister of HRD. GOI on 17th February.
              </div>
            </div>
          </div>
          <div className="award-item">
            <div className="award-thumbnail block animatable bounceInLeft">
              <img
                src="./images/award-3.jpg"
                alt="Eldrok India K-12 Award 2019"
                className="award-image"
              />
            </div>
            <div className="award-content block animatable bounceInRight">
              <div className="award-name">Eldrok India K-12 Award 2019</div>
              <div className="award-description">
                Referred as Brainwonders for Excellent Innovative Learning Practices on 20 November 2019.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;
