import React from 'react'
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const HealthcareBlog = () => {
  return (
    <>
    
    <Navbar />

    <div className="banner">
        <img src="./images/Healthcare-01.jpg" />
      </div>
    
    <div className="page-container">
      <h1>Healthcare Blog</h1>
      <p>GD Goenka, Hyderabad, in partnership with Kaminene Hospital, is dedicated to the health and well-being of our students. Our on-campus healthcare services include routine check-ups, emergency care, and health education programs. We focus on promoting healthy lifestyles and preventive care to ensure that students remain healthy and active throughout their academic journey.</p>
      <p>Our Healthcare Blog provides valuable information on various health topics, including nutrition, mental well-being, and common illnesses. We aim to educate students and parents about maintaining good health practices and staying informed about the latest health trends. Through regular updates and expert advice, we support the holistic development of our students, helping them lead healthy and balanced lives.</p>
    </div>
    
    <Footer />
    
    </>
  )
}

export default HealthcareBlog;