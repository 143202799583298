import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import necessary components
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Classes from "./Pages/Classes";
import Faculty from "./Pages/Faculty";
import More from "./Pages/More";
import SchoolManagment from "./Pages/SchoolManagment";
import Infrastructure from "./Pages/Infrastructure";
import PrivacyPolicy from "./Pages/FooterPages/PrivacyPolicy";
import TermsAndConditions from "./Pages/FooterPages/TermsAndConditions";
import RefundPolicy from "./Pages/FooterPages/RefundPolicy";
import SportsManagement from "./Pages/FooterPages/SportsManagement";
import AdministrationRules from "./Pages/FooterPages/AdministrationRules";
import AdvancedScienceLabs from "./Pages/FooterPages/AdvancedScienceLabs";
import ComputerLab from "./Pages/FooterPages/ComputerLab";
import NewsBlog from "./Pages/FooterPages/NewsBlog";
import HealthcareBlog from "./Pages/FooterPages/HealthcareBlog";
import FAQs from "./Pages/FooterPages/FAQs";
import Curriculum from "./Pages/Curriculum";
import Gallery from "./Pages/Gallery";
import SpeedDialComponent from "./components/SpeedDialComponent";
import MandatoryDisclosure from "./Pages/MandatoryDisclosure";
import EnquireForm from "./Pages/EnquireForm";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/classes" element={<Classes />} />
        <Route path="/faculty" element={<Faculty />} />
        <Route path="/more" element={<More />} />
        <Route path="/schoolmanagment" element={<SchoolManagment />} />
        <Route path="/infrastructure" element={<Infrastructure />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/about" element={<About />} /> */}

{/* footer pages */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/sports-management" element={<SportsManagement />} />
            <Route path="/administration-rules" element={<AdministrationRules />} />
            <Route path="/advanced-science-labs" element={<AdvancedScienceLabs />} />
            <Route path="/computer-lab" element={<ComputerLab />} />
            <Route path="/news-blog" element={<NewsBlog />} />
            <Route path="/healthcare-blog" element={<HealthcareBlog />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/curriculum" element={<Curriculum />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/mandatorydisclosure" element={<MandatoryDisclosure />} />
            <Route path="/enquireform" element={<EnquireForm />} />
            {/* <Route path="/curriculum" element={<Curriculum />} /> */}


            
      </Routes>
      <SpeedDialComponent />
    </React.Fragment>
  );
}

export default App;
