

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const More = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />

      <div className="more-boxes-container">
        <Link to="/schoolmanagment" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-01.jpg" alt="School Management" className="more-box-image" />
          <h1 className="more-box-title">School Management</h1>
        </Link>
        <Link to="/infrastructure" className="more-box animatable block animatable bounceInRight">
          <img src="/images/more-02.jpg" alt="Infrastructure" className="more-box-image" />
          <h1 className="more-box-title">Infrastructure</h1>
        </Link>
        <Link to="/news-blog" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-03.jpg" alt="News Blog" className="more-box-image" />
          <h1 className="more-box-title">News Blog</h1>
        </Link>
        <Link to="/faculty" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-04.jpg" alt="Our Faculty" className="more-box-image" />
          <h1 className="more-box-title">Our Faculty</h1>
        </Link>
        <Link to="/curriculum" className="more-box animatable block animatable bounceInRight">
          <img src="/images/more-05.jpg" alt="Curriculum" className="more-box-image" />
          <h1 className="more-box-title">Curriculum</h1>
        </Link>
        <Link to="/gallery" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-06.jpg" alt="Gallery" className="more-box-image" />
          <h1 className="more-box-title">Gallery</h1>
        </Link>
        <Link to="/mandatorydisclosure" className="more-box animatable block animatable bounceInRight">
          <img src="/images/more-07.jpg" alt="Mandatory Disclosure" className="more-box-image" />
          <h1 className="more-box-title">Mandatory Disclosure</h1>
        </Link>
        <Link to="/enquireform" className="more-box animatable block animatable bounceInLeft">
          <img src="/images/more-08.jpg" alt="Enquire Form" className="more-box-image" />
          <h1 className="more-box-title">Enquire Form</h1>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default More;
